import Cookies from "universal-cookie"
import { get, isEmpty } from "lodash"
import { MYACCOUNT_LOGIN, MYACCOUNT_DASHBOARD } from "../../constants/urls"
import { doRedirect } from "../../components/MyAccount/utils"
import { updateToken } from "./api"
const cookies = new Cookies()

const isBrowser = () => {
    return typeof window !== `undefined`
}

const apiUrl = () => {
    return process.env.REACT_APP_API_URL || process.env.GATSBY_API_URL || null
}

// https://developer.mozilla.org/en-US/docs/Web/API/btoa
const encodeData = data => {
    if (isBrowser() && data) {
        if (typeof btoa === "function") {
            try {
                return btoa(JSON.stringify(data))
            } catch (e) {
                console.warn(e)
                return JSON.stringify(data)
            }
        }
    }
    return data
}

const decodeData = data => {
    if (isBrowser() && data) {
        if (typeof atob === "function") {
            try {
                return JSON.parse(atob(data))
            } catch (e) {
                console.warn(e)
                if (typeof data === 'string') {
                    return JSON.parse(data)
                } else {
                    return data
                }
                // doLogout()
            }
        }
    }
    return data
}

const cookieDomain =
    process.env.REACT_APP_COOKIE_DOMAIN ||
    process.env.GATSBY_COOKIE_DOMAIN ||
    null

const storeData = (key, value, type = "token", customExpires = false) => {
    if (isBrowser()) {
        // localStorage.setItem(key, JSON.stringify(value))
        let expires = new Date(
            new Date().setFullYear(new Date().getFullYear() + 1)
        )
        if (type === "auth")
            expires = new Date(
                new Date().setMinutes(new Date().getMinutes() + 15)
            )
        if (customExpires) expires = customExpires
        const options = {
            expires: expires,
            sameSite: true,
            path: "/",
        }
        if (cookieDomain) options["domain"] = cookieDomain

        cookies.set(key, value, options)
    }
}

const getData = key => {
    return isBrowser() ? cookies.get(key) : null
    // return isBrowser() ? JSON.parse(localStorage.getItem(key)) : null
}

const removeData = key => {
    if (isBrowser()) {
        const options = {
            path: "/",
        }
        // Remove deafult
        cookies.remove(key, options)
        // Remove with domain deafult
        if (cookieDomain) options["domain"] = cookieDomain
        cookies.remove(key, options)
        // localStorage.removeItem(key)
    }
}

const storeUserData = user => {
    let userData = {
        name: (user.forename || user.username || user.email).trim(),
        surname: user.surname || null,
        email: user.email,
        mobile: user.mobile || user.telephone,
        // job_title: user?.job_title,
        uid: user.id,
        contact_id: get(user, "contact_id[0]", false),
        picture: getUserPicture(user),
    }
    storeData("suser", encodeData(userData))
}

const getUser = () => {
    return isBrowser() ? decodeData(cookies.get("suser")) : {}
    // return (isBrowser() ? JSON.parse(localStorage.getItem("suser")) : {});
}

const getToken = () => {
    return isBrowser() ? cookies.get("sjwt") : null
}

const getUserPicture = user => {
    return user?.picture || user?.social_picture || null
}

const doLogout = () => {
    removeData("sjwt")
    removeData("suser")
    removeData("lotp")
    removeSession("sjwt")

    doRedirect(MYACCOUNT_LOGIN)
}

const querystring = params => {
    return new URLSearchParams(params).toString()
}

const storeToken = data => {
    // Store token
    if (isBrowser()) {
        storeData("sjwt", data.jwt)
        storeUserData(data.user)
    }
    updateToken(data.jwt)
}

const getUserPersona = formData => {
    if (isBrowser()) {
        let currentUrl = window.location.pathname
        if (currentUrl.match(/selling/)) return "vendor"
        else if (currentUrl.match(/buying/)) return "sale"
        else if (currentUrl.match(/renting/)) return "let"
        else if (currentUrl.match(/letting/)) return "landlord"

        // switch (formData.form_name) {
        //     case 'viewing_feedback':

        //         break;

        //     default:
        //         return false;
        // }
    }
    return false
}

const isAuthenticated = () => {
    return !isEmpty(getUser())
}

/**
 * SessionStorage
 */
const setSession = (key, value) => {
    sessionStorage.setItem(key, value)
}

const getSession = key => {
    return sessionStorage.getItem(key)
}

const removeSession = key => {
    sessionStorage.removeItem(key)
}

const getUserSession = () => {
    const userSession = getSession("sjwt")
    const userToken = getToken()
    if (userToken == userSession) return true
    return false
}

const setUserSession = () => {
    setSession("sjwt", getToken())
    doRedirect(MYACCOUNT_DASHBOARD)
}

const checkIfCanOtpSend = (time = false) => {
    const lOtp = getData("lotp")

    if (!time) return lOtp ? false : true

    if (lOtp) {
        let minDIff = new Date() - new Date(lOtp)
        if (minDIff) {
            return (5 - minDIff / 1000) * 1000
        }
    }

    return false
}

const setOtpSentFlag = () => {
    storeData(
        "lotp",
        new Date(),
        "",
        new Date(new Date().setMinutes(new Date().getMinutes() + 5))
    )

    // Check if sessionStorage contains data on page load
    document.addEventListener("DOMContentLoaded", function () {
        if (sessionStorage.getItem("sjwt")) {
            // Clear sessionStorage data if present
            sessionStorage.clear()
        }
    })

    // // Define the event listener function
    // function checkAndClearSessionStorage(event) {
    //     if (sessionStorage.getItem("sjwt")) {
    //         sessionStorage.clear();
    //         // Remove the event listener after it has been triggered
    //         document.removeEventListener("DOMContentLoaded", checkAndClearSessionStorage);
    //     }
    // }

    // // Add the event listener for "DOMContentLoaded"
    // document.addEventListener("DOMContentLoaded", checkAndClearSessionStorage);
}

export {
    isBrowser,
    apiUrl,
    storeData,
    getData,
    getUserPicture,
    removeData,
    storeUserData,
    getUser,
    getToken,
    doLogout,
    querystring,
    storeToken,
    getUserPersona,
    isAuthenticated,
    getUserSession,
    setUserSession,
    checkIfCanOtpSend,
    setOtpSentFlag,
}
